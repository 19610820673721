import React, { useEffect, useState } from 'react';
import {PanelProps } from '@grafana/data';
import { PanelOptions } from 'types';
import { css, cx } from '@emotion/css';
import { useStyles2, } from '@grafana/ui';
import { PanelDataErrorView } from '@grafana/runtime';
import { Styles } from 'styles';
import { UPlotReact } from './UPlotReact';
import { UPlotDOMPlugin } from './UPlotDOMPlugin';
import { UPlotOptsBuilder } from './UPlotOptsBuilder';
import { AlignedData } from 'uplot';


import './uPlot.min.css';


let DEFAULT_WIDTH_CHART = 600;
let DEFAULT_HEIGHT_CHART = 300;

// let dataChart: AlignedData = [
//   [0, 1, 2, 3],
//   [0, 5, 0, 7],
// ];

let annos = [7];




/*
 * Properties
*/
interface Props extends PanelProps<PanelOptions> {}


/*
 * Image Panel
*/

export const ChartPanel: React.FC<Props> = ({ options, data, width, height, fieldConfig, id }) => {
  let builder = new UPlotOptsBuilder();  

builder.addSeries({stroke: "red", label: "Pressure"});
builder.addSeries({stroke: "blue", label: "Min"});
console.log("🤡🤡🤡🤡🤡🤡🤡🤡🤡", {builder});

if(builder.opts !== undefined && builder.opts !== null)
{
  // if(builder.opts.scales !== undefined && builder.opts.scales !== null)
  //   {builder.opts.scales.x.time=true;}
  console.log("🪪🪪🪪🪪🪪🪪🪪🪪🪪");
  builder.opts.axes=[
  {
      grid: {
          show: true,
          stroke: "white",
          width: 1,
      },
      ticks: {
          show: true,
          stroke: "white",
          width: 1,
      }
  },
  {
      grid: {
          show: true,
          //stroke: "rgba(0,0,0,0.2)",
          stroke: "white",
          width: 1,
      },
      ticks: {
          show: true,
          stroke: "rgba(0,0,0,0.2)",
          width: 1,
      }
  }
];}
else {
  console.log("🧦🧦🧦🧦🧦🧦🧦🧦🧦", builder.opts);
}
//builder.addSeries({stroke: "blue"});
const [widthChart, setWidthChart] = useState(DEFAULT_WIDTH_CHART);
const [heightChart, setHeightChart] = useState(DEFAULT_HEIGHT_CHART);
useEffect(() => {
  setWidthChart(width-100);
}, [width]);
useEffect(() => {
  setHeightChart(height-100);
}, [height]);
  /*
   * Styles
  */
  console.log("🤡🤡🤡🤡🤡🤡🤡🤡🤡", {data});
  const values = data.series
  .map((series) => series.fields.find((field) => field.name === 'Value'))
  .flatMap((field) => field?.values);
  const minimums = data.series
  .map((series) => series.fields.find((field) => field.name === 'Min'))
  .flatMap((field) => field?.values);
  const times = data.series
  .map((series) => series.fields.find((field) => field.name === 'Time'))
  .flatMap((field) => field?.values);
console.log("🤡🤡🤡🤡🤡🤡🤡🤡🤡", {values}, {times}, {minimums});
//let panelData:PanelData;

  const styles = useStyles2(Styles);
  if (data.series.length === 0) {
    return <PanelDataErrorView  fieldConfig={fieldConfig} panelId={id} data={data as any} needsStringField />;
  }
  let dataChart: AlignedData = [
    times,
    values,
    minimums
  ];
  
  return (
    <div
      className={cx(
        styles.wrapper,
        css`
          width: ${width}px;
          height: ${height}px;
        `
      )}
    >
      <p>Drilling uplot Panel</p>
      <UPlotReact
    data={dataChart as any}
    width={widthChart}
    height={heightChart}    
    builder={builder}
    key={builder.key}
  >
    {() => <div style={{backgroundColor:"green", paddingLeft:"20px"}}><UPlotDOMPlugin builder={builder} annos={annos} /></div>}
  </UPlotReact>
    </div>
  );
};
