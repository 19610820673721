import { css } from "@emotion/css";

/*
 * Styles
*/
export const Styles = () => {
  return {
    wrapper: css`
        font-family: Open Sans;
        position: relative;
        left: 100;
      `,
    img: css`
        position: absolute;
        top: 0;
        left: 100;
      `,
  };
};
