import { Axis, Scales } from "uplot";
import { UPlotOptsDimless } from "./UPlotReact";

// builder pattern for uPlot options
export class UPlotOptsBuilder {
  key = Math.random();

  series: uPlot.Series[] = [{}];

  hooks: uPlot.Hooks.Arrays = {};

  // cached
  opts: UPlotOptsDimless | null = null;
  scales: Scales | null = null;
  axes: Axis[] | null = null;

  addSeries(series: uPlot.Series) {
    this.series.push(series);
  }

  addHooks(hooks: uPlot.Hooks.Arrays) {
    this.hooks = hooks;
  }

  build() {
    if (this.opts == null) {
      this.opts = {
        scales: this.scales === null ? {
          x: {

            time: true,            
            dir: 1,
            ori: 1,
          },
          y: {
            time: false,
            dir: 1,
            ori: 0,
          }
        } : this.scales,
        axes: this.axes === null ? [
          {
            grid: {
              show: true,
              stroke: "rgba(80,80,80,0.6)",
              width: 1,
          },
            side: 3,
            ticks: {
                show: true,
                stroke: "rgba(80,80,80,0.6)",
                width: 1,
            },
            stroke: "white",
          },
          {grid: {
            show: true,
            stroke: "rgba(80,80,80,0.6)",
            width: 1,
        },
            side: 0,
            ticks: {
                show: true,
                stroke: "rgba(80,80,80,0.6)",
                width: 1,
            },
            stroke: "white",
          }
        ] : this.axes,
        series: this.series as any,
        hooks: this.hooks as any,
      };
    }

    return this.opts;
  }
}
