import { PanelPlugin } from '@grafana/data';
import { PanelOptions } from './types';
import { ChartPanel } from './components';
/*
 * Panel Plugin
*/
//export const plugin = new PanelPlugin<PanelOptions>(ImagePanel).setPanelOptions((builder) => {
export const plugin = new PanelPlugin<PanelOptions>(ChartPanel).setPanelOptions((builder) => {
  return builder
    .addTextInput({
      path: 'url',
      name: 'Image URL',
      defaultValue: 'https://',
    });
});
